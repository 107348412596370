@import '../../_modules/extensions';
@import '../../_modules/variables';

.counter-sign .confirmation {
  @extend %agreement-review;

  img {
    cursor: auto !important;
  }

  .success-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $page-header-height;
    padding-top: 32px;

    img {
      width: 73px;
      height: 103px;
    }

    p {
      color: black;
      font-size: 13px;
      max-width: 460px;
      //font-weight: 200;

      &.success {
        font-size: 28px;
        line-height: unset;
        font-weight: 600;
        margin: 10px 0;
      }

      /* for Smartphones (portrait and landscape) */
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        max-width: 80%;
      }
    }

    button {
      margin-top: 20px;
      width: 243px;
    }
  }

  .audit-trail {
    p {
      text-align: left;
    }
  }
}
