.payment-request-button-form {
  .PaymentRequestButton {
    border-radius: 24px;
  }

  .line-separator-container {
    display: flex;
    align-items: center;
    margin: 16px 0;

    .line-text {
      flex-grow: 1;
      text-align: center;
    }

    .line-separator {
      flex-grow: 9;
      height: 1px;
      background-color: #DADDE1;
    }
  }
}
