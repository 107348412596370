@import '../../_modules/variables';

.create-agreement .payment-and-send {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  p {
    text-align: left;
    line-height: 22px;

    &.phone-number {
      color: #8D949E;
    }

    &.discount-description {
      color: #8D949E;
      font-size: 12px;
    }
  }

  main.split-panes {
    display: flex;
    flex-wrap: wrap-reverse;
    min-height: 89vh;

    h3 {
      font-weight: 300;
    }

    .pane {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-end;
      padding: 48px 80px 100px;
      min-height: 100%;

      form {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        width: 350px;

        // TODO: Abstract...
        .inputs {
          margin-bottom: 48px;
          width: 100%;
          display: flex;
          flex-direction: column;

          &.plans-container {
            margin-bottom: 48px;
          }

          .discount-message {
            padding: 10px 10px 0;
            color: #DB4437;
          }

          .credit-card-form-container {
            border: 1px solid #CCD0D5;
            border-radius: 4px;
            padding: 10px;
          }

          .input-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            width: 100%;

            label {
              font-size: 13px;
              color: #1C1E21;
            }

            input {
              margin-top: 4px;
              border: 1px #CCD0D5 solid;
              border-radius: 4px;
              padding: 0 12px;
              width: 100%;
              height: 41px;
              font-size: 13px;
              color: #1C1E21;

              &::placeholder {
                color: #8D949E;
              }
            }

            .password-visibility-toggle {
              top: unset;
              bottom: 10px;
            }
          }

          &.inline-input-with-button {
            margin-bottom: 0;

            .input-container {
              flex-direction: row;
              margin-bottom: 0;

              input {
                flex-grow: 7;
                margin: 0 16px 0 0;
                width: auto;
              }

              button {
                flex-grow: 3;
                background-color: #DADDE1;
                color: #444950;
                font-weight: 400;
                border-radius: 6px;
                height: auto;
                width: auto;
              }
            }
          }
        }

        button {
          width: 100%;
        }

        // TODO: Abstract since it has been used twice
        h5 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #444950;
          font-weight: 300;
          margin: 20px 0;

          div {
            flex-grow: 1;
            text-align: center;

            &.line-separator {
              flex-grow: 9;
              height: 1px;
              background-color: #DADDE1;
            }
          }
        }

        .terms-accepted-checkbox {
          margin: 0;
        }

        .space-between-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .edit {
            width: auto;
            background-color: transparent;
            color: #1763CF;
            font-size: 13px;
          }

          .discount-title {
            color: #FA383E;
          }
        }

        .plans {
          display: flex;

          .border-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            flex-basis: 0;
            border: 1px #979797 solid;
            border-radius: 3px;
            height: 100px;
            cursor: pointer;

            &:first-child {
              margin-right: 26px;
            }

            &.active {
              border-color: $tint-color;

              p {
                color: $tint-color;
              }
            }
          }
        }

        .oauth-buttons {
          display: flex;
          justify-content: space-between;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 47%;

            span {
              margin-left: 12px;
            }

            &.facebook-btn {
              background-color: #1877F2;
            }

            &.google-btn {
              background-color: #DB4437;
            }
          }
        }

        .price-container {
          display: flex;
          align-items: center;

          .currency {
            color: #8D949E;
            margin-right: 8px;
          }

          .price {
            font-size: 28px;
          }
        }
      }

      &.summery-pane {
        background-color: #F9F7F5;
        align-items: flex-start;
      }
    }

    // become one field per row
    @media only screen and (max-width: 1034px) {
      margin-top: 0;

      .pane {
        padding: 0;
        align-items: stretch;

        &.payment-pane {
          padding-bottom: 16px;
        }

        form {
          padding: 24px 20px 16px;
          width: 100%;
        }
      }
    }
  }
}
