@import '../_modules/variables';

.pn-button {
  max-width: 100%;
  width: 366px;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: $tint-color;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
