.password-input-container {
  position: relative;
}

.password-visibility-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px;
  cursor: pointer;

  &.enabled {
    color: #545459;
  }

  &.disabled {
    color: #C3C3C7;
  }

  &:hover {
    color: #212125;
  }
}
