.pn-checkbox {
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;

  .checked-mark-container {
    flex-basis: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(204, 208, 213, 0.3);
    border: 1px solid rgb(204, 208, 213);
    border-radius: 4px;
    height: 20px;
  }

  p {
    text-align: left;
    margin: 0 0 0 16px;
    font-size: 15px;
  }
}
