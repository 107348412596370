@import '../_modules/variables';

$max-input-width: 714px;

.contact-us {
  margin-top: $page-header-height;

  h2 { // 32/46
    width: unset;
    padding: 0 20px;
    color: #242526;

    /* for Smartphones (portrait and landscape) */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      font-weight: 700;
    }
  }

  p.sub-title { // 20/28
    font-size: 20px;
    line-height: 28px;
    padding: 0 20px;
    color: #3E4042;
    max-width: 913px;

    &.bold {
      margin-bottom: 0;
    }

    /* for Smartphones (portrait and landscape) */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0 34px;

    /* for Smartphones (portrait and landscape) */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      padding: 40px 0 4px;
    }
  }

  .general-inquiries {
    padding: 0 20px 44px;

    /* for Smartphones (portrait and landscape) */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      padding-bottom: 24px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      .inquiry-type-select,
      input,
      textarea,
      button {
        margin-bottom: 16px;
        max-width: 100%;
        width: $max-input-width;
        height: 42px;
      }

      .inquiry-type-select {
        .ant-select-selection {
          height: 42px;
        }

        .ant-select-selection__rendered,
        .ant-select-selection-selected-value {
          height: 40px;
          line-height: 40px;
        }

        .ant-select-selection-selected-value {
          color: #1C1E21;
        }

        .ant-select-selection__placeholder {
          color: #8D949E;
        }
      }

      input,
      textarea {
        border: 1px #CCD0D5 solid;
        border-radius: 4px;
        padding: 0 12px;
        height: 42px;
        font-size: 13px;
        color: #1C1E21;

        &::placeholder {
          color: #8D949E;
        }
      }

      textarea {
        padding: 12px;
        height: unset;
      }

      button {
        margin-top: 4px;
        height: 50px;
      }
    }
  }
}

