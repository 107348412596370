@import '../_modules/variables';
@import '../_modules/extensions';

.about-us {
  @extend %new-design;

  color: #212121;
  margin-top: $page-header-height;

  * {
    font-family: 'Montserrat';
  }

  .intro {
    .intro-statements {
      .buttons {
        flex-wrap: wrap-reverse;

        @media only screen and (max-width: $image-overstretched-breakpoint) {
          justify-content: center;
        }

        .watch-video-btn {
          flex-basis: 180px;
          margin-bottom: 9px;
        }

        .learn-more-btn {
          flex-basis: 360px;
          margin-bottom: 9px;
        }
      }
    }

    .intro-image {
      @media only screen and (max-width: $image-overstretched-breakpoint) {
        background-image: url('/images/ceo-profile.png');
        background-position: center top;
      }
    }
  }

  .privacy {
    .privacy-image {
      background-image: url('/images/ceo-profile.png') !important;
      background-position: center top !important;

      @media only screen and (max-width: $image-overstretched-breakpoint) {
        background-image:
          linear-gradient(to right, rgba(62, 224, 249, 0.46), rgba(255, 13, 141, 0.46)),
          url('/images/omg_mobile.png') !important;
        margin: -16px 0 0 0 !important;
      }
    }
  }

  .our-mission {
    background-color: #142F46;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 30px;// TODO

    * {
      color: white;
      text-align: center;
    }

    h2 {
      margin-bottom: 26px;
    }

    p {
      max-width: 1120px;
    }

    button {
      width: 180px;
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      padding: 25px 30px; // TODO

      h2 {
        margin-bottom: 8px;
        font-size: 23px;
        line-height: 28px;
      }

      p {
        margin: 0 0 20px;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

