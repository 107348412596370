@import '../_modules/variables';

.my-dashboard {
  display: flex;
  width: 100%;
  min-height: 100%;

  main {
    margin-top: $page-header-height;
    background-color: #F9F7F5;
    flex-grow: 1;
    display: flex;

    .white-board {
      flex-grow: 1;
      margin: 58px 73px;
      background-color: white;

      /* when action buttons overflow */
      @media only screen and (max-width: 671px) {
        margin: 16px 16px 24px;
      }

      .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EBEDF0;
        padding: 0 24px;
        height: 80px;
        font-size: 20px;
        font-weight: normal;
        color: #212B36;

        /* Smartphones (portrait and landscape) ----------- */
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          padding: 0 12px;
        }

        h4 {
          margin: 0;
        }

        button {
          width: 100px;
          height: 33px;
        }
      }

      .panes {
        display: flex;
        flex-direction: row;

        /* when action buttons overflow */
        @media only screen and (max-width: 671px) {
          flex-direction: column;
        }

        .pane {
          flex-grow: 7;

          /* when action buttons overflow */
          @media only screen and (max-width: 671px) {
            padding: 0 24px;
          }

          /* Smartphones (portrait and landscape) ----------- */
          @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
            padding: 0;
          }

          &.control {
            flex-grow: 2;
            padding: 32px;

            /* when action buttons overflow */
            @media only screen and (max-width: 671px) {
              border-bottom: 1px solid #EBEDF0;
            }

            /* Smartphones (portrait and landscape) ----------- */
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
              padding: 0 24px;
            }
          }
        }
      }
    }
  }

  .sort-by-select {
    width: 250px;
    max-width: 250px;

    /* when action buttons overflow */
    @media only screen and (max-width: 1043px) {
      width: 100%;
    }
  }

  .tag {
    background-color: grey;
    color: white;
    margin-right: 13px;
    border-radius: 3px;
    padding: 4px 0;
    width: 93px;
    min-width: 93px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;

    &.success {
      background-color: #63BE09;
    }

    &.warning {
      background-color: #FFBA00;
    }
  }

  .pn-timeline {
    &.vertical-timeline {
      padding: 2em 0 0;
    }

    &.vertical-timeline:before {
      background-color: #EBEDF0;
      width: 1px;
    }

    .timeline-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      font-weight: 500;

      // the following style are copied from library
      width: 24px;
      height: 24px;
      position: relative;
      left: 50%;
      top: 50%;
      margin-left: -12px;
      margin-top: -12px;
    }

    .timeline-element {
      display: flex;
      align-items: center;
      justify-content: space-between;

      /* when action buttons overflow */
      @media only screen and (max-width: 1043px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .timeline-element-header {
        display: flex;
        align-items: center;

        /* for Smartphones (portrait and landscape) */
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .timeline-element-title {
        margin: 16px 0 5px;
      }

      .timeline-element-subtitle {
        font-weight: normal;
        color: #444950;
        margin-bottom: 12px;
      }

      button {
        background-color: rgba(0, 0, 0, 0.05);
        color: #444950;
        margin-right: 8px;
        border-radius: 6px;
        padding: 0 16px;
        height: 36px;
        font-size: 15px;
        display: inline-flex;
        align-items: center;

        &.sign-now {
          background-color: $tint-color;
          color: #F9F7F5;
        }

        span {
          margin-left: 8px;
        }
      }
    }

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      .vertical-timeline-element-content {
        padding: 1em 0 1em 1em;
      }
    }
  }
}
