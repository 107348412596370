@import '../_modules/variables';

$collapse-break-point: 1200px;

.pn-page-header {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);

  .nav-content-container {
    @media only screen and (min-width: 1200px) {
      max-width: 1600px;
    }
  }

  a {
    text-align: unset;
  }

  .navbar-brand {
    padding: 0;

    img {
      cursor: pointer;
    }
  }

  &.navbar-light .navbar-nav .nav-link {
    color: #212121;

    &.active {
      color: $tint-color;
    }
  }

  .dropdown-item {
    font-size: 20px;
    font-weight: 700;
  }

  .nav-link {
    white-space: nowrap;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: -0.5px;
    margin: 0 10px;

    @media only screen and (max-width: $collapse-break-point) {
      &.first-nav-link {
        margin-top: 8px;
      }
    }

    @media only screen and (min-width: $collapse-break-point) {
      &.create-agreement {
        color: white !important;
        background-color: $tint-color;
        border-radius: 30px;
        width: 290px;
        height: 43px;
        text-align: center;
        font-weight: bold;
      }
    }

    &.dropdown-toggle {
      @media only screen and (min-width: $collapse-break-point) {
        padding: 0;

        &:after {
          content: none;
        }
      }
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $tint-color;
  }
}
