@import './_modules/fonts';
@import './_modules/normalize';

* {
  font-family: 'SF Text';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  color: #444950;
  font-size: 14px;
}

html,
body,
#play-nice-react-root {
  width: 100%;
  height: 100%;
}

button {
  border: none;
  outline: none !important;
  cursor: pointer;
  font-family: 'SF Text';

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

p {
  font-size: 13px;
  line-height: 17px;
  color: #606770;
  text-align: center;

  &.bold {
    font-weight: 700;
  }
}

a,
.link {
  text-decoration: none;
  color: #1877F2;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

input:disabled {
  background-color: rgba(204, 208, 213, 0.3);
}

.custom-modal {
  .ant-modal-content {
    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .clear-signature {
        align-self: flex-end;
      }

      .canvas-container {
        border-bottom: 1px solid #979797;
      }

      p {
        margin: 20px 0;
        letter-spacing: -1px;
        font-size: 13px;
        color: #444950;
      }

      button {
        max-width: 100%;
      }
    }
  }
}

.align-left {
  text-align: left;
}

.iframe-container {
  position: relative;
  padding-top: 56.25%; // Calculated from the aspect ratio of the content (in case of 16:9 it is 9/16= 0.5625)
  width: 100%;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    height: 100%;
    width: 100%;
  }
}

.custom-dropdown-date {
  display: flex;

  .custom-dropdown-date-select {
    background-color: white;
    margin: 4px 2px 0;
    border: 1px #CCD0D5 solid;
    height: 41px;
    font-size: 13px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
