@import '../_modules/extensions';

.privacy-policy {
  @extend %terms-of-use-privacy-policy;

  h6,
  .underline {
    text-decoration: underline;
  }

  .list-sub-title {
    font-size: 0.9rem;
  }

  .sub-title-without-underline {
    text-decoration: none;
    font-size: 0.9rem;
  }
}
