@import '../../_modules/variables';

.steps-header {
  display: flex;
  justify-content: center;
  margin-top: $page-header-height;
  height: $page-header-height;
  background-color: #212B36;

  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;

    div {
      flex-grow: 4;
      flex-basis: 0;
      text-align: center;
      color: white;
      opacity: 0.5;
      font-size: 16px;
      font-weight: 500;

      &.left-caret {
        flex-grow: 1;
      }

      &.current-step {
        opacity: 1;
      }

      /* for Smartphones (portrait and landscape) */
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        font-size: 13px;
        line-height: 17px;
      }
    }

    /* for Smartphones (portrait and landscape) */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      padding: 0 30px;
    }
  }

  /* for iPads and Smartphones (portrait and landscape) */
  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    margin-top: $page-header-height;
  }
}
