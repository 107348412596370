/* --------------------------------------------------------------------------- *
 * Montserrat
 * --------------------------------------------------------------------------- */

/** Black */
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('/fonts/Montserrat-Black.ttf');
}

/** Black Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-style: italic;
  src: url('/fonts/Montserrat-BlackItalic.ttf');
}

/** Heavy */
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('/fonts/Montserrat-ExtraBold.ttf');
}

/** Heavy Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: italic;
  src: url('/fonts/Montserrat-ExtraBoldItalic.ttf');
}

/** Bold */
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('/fonts/Montserrat-Bold.ttf');
}

/** Bold Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/Montserrat-BoldItalic.ttf');
}

/** Semibold */
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('/fonts/Montserrat-SemiBold.ttf');
}

/** Semibold Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: italic;
  src: url('/fonts/Montserrat-SemiBoldItalic.ttf');
}

/** Medium */
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('/fonts/Montserrat-Medium.ttf');
}

/** Medium Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: italic;
  src: url('/fonts/Montserrat-MediumItalic.ttf');
}

/** Regular */
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('/fonts/Montserrat-Regular.ttf');
}

/** Regular Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/Montserrat-RegularItalic.ttf');
}

/** Thin */
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('/fonts/Montserrat-Thin.ttf');
}

/** Thin Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: italic;
  src: url('/fonts/Montserrat-ThinItalic.ttf');
}

/** Light */
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url('/fonts/Montserrat-Light.ttf');
}

/** Light Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: italic;
  src: url('/fonts/Montserrat-LightItalic.ttf');
}

/** Ultralight */
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: url('/fonts/Montserrat-ExtraLight.ttf');
}

/** Ultralight Italic */
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-style: italic;
  src: url('/fonts/Montserrat-ExtraLightItalic.ttf');
}

/* --------------------------------------------------------------------------- *
 * SF UI Display
 * --------------------------------------------------------------------------- */

/** Black */
@font-face {
  font-family: 'SF Display';
  font-weight: 900;
  src: url('/fonts/SF-UI-Display-Black.otf');
}

/** Heavy */
@font-face {
  font-family: 'SF Display';
  font-weight: 800;
  src: url('/fonts/SF-UI-Display-Heavy.otf');
}

/** Bold */
@font-face {
  font-family: 'SF Display';
  font-weight: 700;
  src: url('/fonts/SF-UI-Display-Bold.otf');
}

/** Semibold */
@font-face {
  font-family: 'SF Display';
  font-weight: 600;
  src: url('/fonts/SF-UI-Display-Semibold.otf');
}

/** Medium */
@font-face {
  font-family: 'SF Display';
  font-weight: 500;
  src: url('/fonts/SF-UI-Display-Medium.otf');
}

/** Regular */
@font-face {
  font-family: 'SF Display';
  font-weight: 400;
  src: url('/fonts/SF-UI-Display-Regular.otf');
}

/** Thin */
@font-face {
  font-family: 'SF Display';
  font-weight: 300;
  src: url('/fonts/SF-UI-Display-Thin.otf');
}

/** Light */
@font-face {
  font-family: 'SF Display';
  font-weight: 200;
  src: url('/fonts/SF-UI-Display-Light.otf');
}

/** Ultralight */
@font-face {
  font-family: 'SF Display';
  font-weight: 100;
  src: url('/fonts/SF-UI-Display-Ultralight.otf');
}

/* --------------------------------------------------------------------------- *
 * SF UI Text
 * --------------------------------------------------------------------------- */

/** Heavy */
@font-face {
  font-family: 'SF Text';
  font-weight: 800;
  src: url('/fonts/SF-UI-Text-Heavy.otf');
}

/** Heavy Italic */
@font-face {
  font-family: 'SF Text';
  font-weight: 800;
  font-style: italic;
  src: url('/fonts/SF-UI-Text-HeavyItalic.otf');
}

/** Bold */
@font-face {
  font-family: 'SF Text';
  font-weight: 700;
  src: url('/fonts/SF-UI-Text-Bold.otf');
}

/** Bold Italic */
@font-face {
  font-family: 'SF Text';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/SF-UI-Text-BoldItalic.otf');
}

/** Semibold */
@font-face {
  font-family: 'SF Text';
  font-weight: 600;
  src: url('/fonts/SF-UI-Text-Semibold.otf');
}

/** Semibold Italic */
@font-face {
  font-family: 'SF Text';
  font-weight: 600;
  font-style: italic;
  src: url('/fonts/SF-UI-Text-SemiboldItalic.otf');
}

/** Medium */
@font-face {
  font-family: 'SF Text';
  font-weight: 500;
  src: url('/fonts/SF-UI-Text-Medium.otf');
}

/** Medium Italic */
@font-face {
  font-family: 'SF Text';
  font-weight: 500;
  font-style: italic;
  src: url('/fonts/SF-UI-Text-MediumItalic.otf');
}

/** Regular */
@font-face {
  font-family: 'SF Text';
  font-weight: 400;
  src: url('/fonts/SF-UI-Text-Regular.otf');
}

/** Regular Italic */
@font-face {
  font-family: 'SF Text';
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/SF-UI-Text-RegularItalic.otf');
}

/** Light */
@font-face {
  font-family: 'SF Text';
  font-weight: 200;
  src: url('/fonts/SF-UI-Text-Light.otf');
}

/** Light Italic */
@font-face {
  font-family: 'SF Text';
  font-weight: 200;
  font-style: italic;
  src: url('/fonts/SF-UI-Text-HeavyItalic.otf');
}

/** Ultrathin */
@font-face {
  font-family: 'SF Text';
  font-weight: 100;
  src: url('/fonts/SF-UI-Text-Ultrathin.otf');
}

/** Ultrathin Italic */
@font-face {
  font-family: 'SF Text';
  font-weight: 100;
  font-style: italic;
  src: url('/fonts/SF-UI-Text-UltrathinItalic.otf');
}

/* --------------------------------------------------------------------------- *
 * SF Mono
 * --------------------------------------------------------------------------- */

/** Heavy */
@font-face {
  font-family: 'SF Mono';
  font-weight: 800;
  src: url('/fonts/SFMono-Heavy.otf');
}

/** Heavy Italic */
@font-face {
  font-family: 'SF Mono';
  font-weight: 800;
  font-style: italic;
  src: url('/fonts/SFMono-HeavyItalic.otf');
}

/** Bold */
@font-face {
  font-family: 'SF Mono';
  font-weight: 700;
  src: url('/fonts/SFMono-Bold.otf');
}

/** Bold Italic */
@font-face {
  font-family: 'SF Mono';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/SFMono-BoldItalic.otf');
}

/** Semibold */
@font-face {
  font-family: 'SF Mono';
  font-weight: 600;
  src: url('/fonts/SFMono-Semibold.otf');
}

/** Semibold Italic */
@font-face {
  font-family: 'SF Mono';
  font-weight: 600;
  font-style: italic;
  src: url('/fonts/SFMono-SemiboldItalic.otf');
}

/** Medium */
@font-face {
  font-family: 'SF Mono';
  font-weight: 500;
  src: url('/fonts/SFMono-Medium.otf');
}

/** Medium Italic */
@font-face {
  font-family: 'SF Mono';
  font-weight: 500;
  font-style: italic;
  src: url('/fonts/SFMono-MediumItalic.otf');
}

/** Regular */
@font-face {
  font-family: 'SF Mono';
  font-weight: 400;
  src: url('/fonts/SFMono-Regular.otf');
}

/** Regular Italic */
@font-face {
  font-family: 'SF Mono';
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/SFMono-RegularItalic.otf');
}

/** Light */
@font-face {
  font-family: 'SF Mono';
  font-weight: 200;
  src: url('/fonts/SFMono-Light.otf');
}

/** Light Italic */
@font-face {
  font-family: 'SF Mono';
  font-weight: 200;
  font-style: italic;
  src: url('/fonts/SFMono-HeavyItalic.otf');
}
