@import '../_modules/variables';
@import '../_modules/extensions';

.home {
  @extend %new-design;

  color: #212121;
  margin-top: $page-header-height;

  * {
    font-family: 'Montserrat';
  }

  .impact {
    &.visible-in-mobile {
      display: none;
    }

    &.hidden-in-mobile {
      display: flex;
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      &.visible-in-mobile {
        display: flex;
      }

      &.hidden-in-mobile {
        display: none;
      }
    }
  }

  .privacy {
    p.above-create-agreement-button {
      margin-bottom: 20px;
    }

    .create-agreement-btn {
      width: 253px;
    }

    .privacy-highlights {
      display: flex;
      margin: 43px 0;

      .vertical-separator {
        margin-right: 26px;
      }

      .highlights-container {
        align-self: center;

        p.no-margin {
          margin: 0;
        }
      }
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      .create-agreement-btn {
        width: 174px;
      }

      .privacy-highlights {
        margin: 24px 0 10px;

        .vertical-separator {
          height: 285px;
          margin-right: 41px;

          @media only screen and (min-width: 480px) and (max-width: $image-overstretched-breakpoint) {
            height: 169px;
          }
        }

        p {
          text-align: left;
        }
      }
    }
  }

  .features {
    .features-row {
      display: flex;

      .feature-container {
        display: flex;
        width: 50%;

        &:first-child {
          margin-right: 70px;
        }

        .feature-icon {
          align-self: flex-start;
          margin-right: 34px;
          height: auto;
          width: 130px;
        }
      }

      @media only screen and (max-width: $image-overstretched-breakpoint) {
        flex-direction: column;

        .feature-container {
          width: unset;
          flex-direction: column;

          &:first-child {
            margin-right: unset;
          }

          .feature-icon {
            width: 60px;
          }

          h3 {
            position: relative;
            left: 80px;
            bottom: 41px;
            max-width: 170px;
          }

          p {
            margin: -18px 0 30px;
            font-size: 10px;
            line-height: 13px;
          }
        }
      }
    }
  }

  .testimonials {
    padding: 64px 100px 48px;

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      padding: 13px 32px 28px;
    }

    .testimonials-intro {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        text-align: center;
        max-width: 800px;
        font-size: 16px;
        line-height: 24px;
      }

      @media only screen and (max-width: $image-overstretched-breakpoint) {
        h2 {
          margin-bottom: 8px;
          font-size: 23px;
          line-height: 25px;
        }

        p {
          max-width: 550px;
        }
      }
    }

    .testimonials-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .testimonial-container {
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.22);
        border-radius: 8px;
        margin: 0 25px 32px;
        max-width: 100%;
        width: 342px;

        .testimonial-image {
          width: 100%;
          height: auto;
        }

        .description-container {
          padding: 16px 20px;

          h2 {
            text-align: left;
            color: #606770;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
            min-width: 299px;
          }

          p {
            text-align: left;
            color: #3E4042;
            margin: 0;
            font-weight: 600;
            font-size: 15px;
            line-height: 21px;
          }
        }
      }
    }

    .testimonials-footer {
      display: flex;
      justify-content: center;

      .testimonials-footer-btn {
        width: 300px;

        @media only screen and (max-width: $image-overstretched-breakpoint) {
          width: 281px;
        }
      }
    }
  }
}
