@import '../_modules/variables';
@import '../_modules/extensions';

$steps-intro-max-width: 571px;
$steps-details-max-width: 416px;
$all-protections-max-width: 1315px;

.how-it-works {
  @extend %new-design;

  color: #212121;
  margin-top: $page-header-height;

  * {
    font-family: 'Montserrat';
  }

  .intro {
    .intro-image {
      background-image: url('/images/how-it-works.png') !important;

      @media only screen and (max-width: $image-overstretched-breakpoint) {
        background-image: url('/images/how-it-works_mobile.png') !important;
      }
    }
  }

  .privacy {
    .privacy-image {
      background-image: url('/images/privacy_how-it-works.png') !important;

      @media only screen and (max-width: $image-overstretched-breakpoint) {
        background-image:
          linear-gradient(to right, rgba(62, 224, 249, 0.46), rgba(255, 13, 141, 0.46)),
          url('/images/privacy_how-it-works.png') !important;
      }
    }
  }

  .impact {
    @media only screen and (max-width: $image-overstretched-breakpoint) {
      background-color: white !important;

      .impact-intro {
        display: none;
      }
    }
  }

  .steps {
    display: flex;
    justify-content: center;
    background-color: #142F46;
    padding: 80px 0;

    * {
      color: white;
    }

    h2,
    p {
      margin-bottom: 32px;
      padding: 0 20px;
    }

    .steps-intro {
      flex-grow: 137;
      max-width: $steps-intro-max-width;
      padding-right: 77px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .get-started-btn {
        margin: 0 20px;
        width: 281px;
      }

      @media only screen and (max-width: $steps-intro-max-width + $steps-details-max-width) {
        padding-right: 0;

        .get-started-btn {
          width: 224px;
          height: 48px;
          font-size: 16px;
          border-radius: 24px;
        }
      }
    }

    .steps-details {
      flex-grow: 100;
      max-width: $steps-details-max-width;
    }

    // become single column of steps
    @media only screen and (max-width: 758px) {
      padding: 30px 0;

      .steps-intro {
        display: none;
      }
    }
  }

  .our-plans {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 40px 60px;

    .our-plans-img {
      margin-right: 50px;
      width: 514px;
      height: 802px;
    }

    .our-plans-highlights {
      display: flex;
      flex-direction: column;
      max-width: 700px;

      h2 {
        font-size: 30px;
        line-height: 37px;
      }

      .plan {
        margin-bottom: 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: 1px #DADDE1 solid;
        border-radius: 2px;
        padding: 20px 40px;

        h5 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 900;
          color: #FE5CB6;
        }

        h6 {
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
        }
      }
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      flex-direction: column;
      padding: 30px 25px 10px;

      .our-plans-img {
        display: none;
      }

      .our-plans-highlights {
        h2 {
          font-size: 23px;
          line-height: 28px;
        }

        p {
          margin-bottom: 16px;
          font-size: 13px;
          line-height: 16px;
        }

        .plan {
          padding: 20px;

          * {
            margin-bottom: 4px;
          }

          h2 {
            font-size: 20px;
            line-height: 24px;
          }

          h5 {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .features {
    .features-container {
      padding-top: 50px;

      h2,
      p {
        text-align: center;
      }

      h2 {
        margin-bottom: 32px;
      }

      a {
        color: #FF0D8D;
      }

      @media only screen and (max-width: $image-overstretched-breakpoint) {
        padding-top: 16px;

        h2 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 12px;
        }

        p {
          font-size: 11px;
          line-height: 15px;
          margin-bottom: 16px;
        }
      }
    }
  }

  .protections {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F0F0F0;
    padding: 50px 0 34px;

    .protections-blocks {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      max-width: $all-protections-max-width;
      width: 100%;

      @media only screen and (max-width: $all-protections-max-width) {
        justify-content: center;
      }

      .protection-block {
        margin: 0 25px 30px;
        max-width: 100%;
        width: 600px;
        font-size: 26px;
        font-weight: 700;
      }
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      padding: 16px 0 9px;

      h2 {
        font-size: 23px;
        line-height: 28px;

        /* for Smartphones (portrait and landscape) */
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          align-self: flex-start;
          margin: 0 25px;
        }
      }

      .protections-blocks {
        margin-top: 10px;

        .protection-block {
          margin-bottom: 10px;
          font-size: 18px;
        }
      }
    }
  }

  .pn-timeline-basic {
    padding: 0;
    width: 88%;

    p {
      margin-bottom: 0;
      padding: 0;
    }

    .timeline-basic-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      font-weight: 500;

      // the following style are copied from library
      width: 24px;
      height: 24px;
      position: relative;
      left: 50%;
      top: 50%;
      margin-left: -12px;
      margin-top: -12px;
    }

    .timeline-basic-element-title {
      font-size: 24px;
      line-height: 29px;
      font-weight: 900;

      /* for Smartphones (portrait and landscape) */
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .timeline-basic-element-description {
      font-size: 14px;
      line-height: 24px;

      /* for Smartphones (portrait and landscape) */
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        font-size: 16px;
      }

      &.sub-title { // 20/28
        font-size: 20px;
        line-height: 28px;

        /* for Smartphones (portrait and landscape) */
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .vertical-timeline-element {
      margin: 0;

      .vertical-timeline-element-content {
        padding: 7px 0 15px 11px;
      }
    }
  }
}

