@import '../_modules/variables';

%common-agreement-flow {
  h3 {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
    color: #212B36;
    letter-spacing: 0.2px;

    &.with-subtitle {
      margin-bottom: 8px;
    }
  }

  h4 {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #606770;
  }

  .form-row {
    display: flex;
    flex-direction: column;

    button {
      max-width: 100%;
    }

    p {
      opacity: 0.84;
    }
  }

  /* for Smartphones (portrait and landscape) */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    h3 {
      font-size: 16px;
    }

    main {
      margin-top: 24px;
    }
  }
}

%basic-info {
  display: flex;
  flex-direction: column;

  main {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    padding: 0 20px;
    width: 100%;
    max-width: 500px;

    form {
      width: 100%;

      // TODO: Abstract...
      .inputs {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .input-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          width: 100%;

          label {
            font-size: 13px;
            color: #1C1E21;
          }

          input {
            margin-top: 4px;
            border: 1px #CCD0D5 solid;
            border-radius: 4px;
            padding: 0 12px;
            width: 100%;
            height: 41px;
            font-size: 13px;
            color: #1C1E21;

            &::placeholder {
              color: #8D949E;
            }
          }
        }
      }

      .form-row {
        align-items: center;

        button {
          width: 100%;
        }

        p {
          margin-top: 16px;
        }
      }
    }
  }
}

%agreement-review {
  display: flex;
  flex-direction: column;

  h6 {
    margin-bottom: 12px;
    color: #1C1E21;
    font-weight: 300;
  }

  p {
    color: #444950;
    line-height: 17px;
    font-weight: 300;
  }

  main {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: 48px 0;
    padding: 0 20px;
    width: 100%;
    max-width: 1140px;

    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 28px;
      color: #212B36;
      font-weight: 300;

      div {
        flex-grow: 1;
        text-align: center;

        &.line-separator {
          flex-grow: 9;
          height: 1px;
          background-color: #DADDE1;
        }
      }
    }

    .overview-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 40px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 40px;

        img {
          width: 110px;
          height: 100px;
        }

        h6 {
          margin-top: 30px;
          font-size: 20px;
        }

        p {
          width: 250px;
        }

        /* for Smartphones (portrait and landscape) */
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          h6 {
            font-weight: 600;
          }

          p {
            width: 100%;
          }
        }
      }
    }

    .covered-or-not-container {
      margin: 0 0 40px;
      padding: 32px 30px;
      background-color: #F5F6F7;

      h6 {
        font-size: 14px;
      }

      p {
        margin-bottom: 20px;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .document-container {
      h2 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 1em;
      }

      p {
        font-size: 13px;
        text-indent: 2em;
        text-align: left;
        margin-bottom: 1em;

        &.double-indent {
          text-indent: 2 * 2em;
        }
      }

      span {
        text-decoration: underline;
      }
    }

    .pdfobject-container {
      width: 100%;
      height: 600px;
    }

    form {
      margin-top: 35px;
      width: 100%;

      h6 {
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 20px;
      }

      // TODO: Abstract...
      .inputs {
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .input-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 21px;
          width: 353px;

          // become one input per row
          @media only screen and (max-width: 1098px) {
            width: 100%;

            &.signature {
              margin-top: 42px;
            }
          }

          label {
            margin-top: 9px;
            font-size: 13px;
            font-weight: 300;
            color: #444950;
          }

          div {
            border-bottom: 1px #BEC3C9 solid;
            width: 353px;
            height: 28px;
            font-weight: 300;
            font-size: 16px;
            color: #1C1E21;

            &.will-send-text {
              font-size: 14px;
              color: #8D949E;

              &.will-sign-text {
                color: $tint-color;
                opacity: 0.6;
              }
            }

            img {
              margin-top: -42px;
              width: 100px;
              height: 62px;
              cursor: pointer;
            }

            // become one input per row
            @media only screen and (max-width: 1098px) {
              width: 100%;
            }
          }
        }
      }

      .form-row {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;

        // become one element per row
        @media only screen and (max-width: 1098px) {
          p {
            text-align: left;
          }

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

%verify-phone-number {
  display: flex;
  flex-direction: column;

  main {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    padding: 0 20px;
    width: 100%;
    max-width: 394px;

    form {
      width: 100%;

      // TODO: Abstract...
      .inputs {
        //margin-bottom: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .input-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          width: 100%;

          label {
            font-size: 13px;
            color: #1C1E21;
          }

          input {
            margin-top: 4px;
            border: 1px #CCD0D5 solid;
            border-radius: 4px;
            padding: 0 12px;
            width: 100%;
            height: 41px;
            font-size: 13px;
            color: #1C1E21;

            &::placeholder {
              color: #8D949E;
            }
          }
        }
      }

      .form-row {
        button {
          margin-top: 32px;
        }

        p {
          text-align: left;
        }
      }
    }
  }
}

%create-update-account {
  display: flex;
  flex-direction: column;

  main {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 170px 0 80px;
    padding: 0 20px;
    width: 100%;
    max-width: 824px;

    /* for Smartphones (portrait and landscape) */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      margin-top: $page-header-height + 20px;
    }

    h4 {
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }

    hr {
      margin: 20px 0;
      border: 0.5px solid #D8D8D8;
    }

    form {
      margin-top: 32px;
      width: 100%;

      // TODO: Abstract...
      .inputs {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .input-container {
          display: flex;
          flex-direction: column;
          margin-bottom: 21px;
          width: 366px;

          // become one input per row
          @media only screen and (max-width: 771px) {
            width: 100%;
          }

          label {
            font-size: 13px;
            color: #1C1E21;
          }

          input {
            margin-top: 4px;
            border: 1px #CCD0D5 solid;
            border-radius: 4px;
            padding: 0 12px;
            width: 366px;
            height: 41px;
            font-size: 13px;
            color: #1C1E21;

            // become one input per row
            @media only screen and (max-width: 771px) {
              width: 100%;
            }

            &::placeholder {
              color: #8D949E;
            }
          }

          .password-visibility-toggle {
            top: unset;
            bottom: 10px;
          }
        }
      }

      .inputs-agreement-top-separator {
        margin-top: 11px;
      }

      .inputs-agreement-bottom-separator {
        margin-top: 4px;
      }

      .form-row {
        display: flex;
        flex-flow: row wrap-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 46px;

        p {
          opacity: 0.84;
        }

        // become one element per row
        @media only screen and (max-width: 657px) {
          p {
            margin: auto;
          }

          button {
            margin: 0 auto 24px;
          }
        }
      }
    }
  }
}

%login-forgot-password {
  display: flex;
  justify-content: center;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 170px 0 40px;
    padding: 0 20px;
    width: 100%;
    max-width: 406px;

    img {
      margin-bottom: 20px;
      width: 51px;
      height: 76px;
    }

    form {
      margin-top: 37px;
      width: 100%;
      display: flex;
      flex-direction: column;

      input {
        margin-bottom: 16px;
        border: 1px #CCD0D5 solid;
        border-radius: 4px;
        padding: 0 12px;
        width: 100%;
        height: 41px;
        font-size: 13px;
        color: #1C1E21;
        flex-wrap: wrap-reverse;
      }

      .submit-button {
        margin: 8px 0 32px;
        max-width: 100%;
      }
    }
  }

  /* for Smartphones (portrait and landscape) */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    main {
      margin-top: $page-header-height + 20px;

      form {
        width: 100%;

        .submit-button {
          width: 100%;
        }
      }
    }
  }
}

%terms-of-use-privacy-policy {
  margin-top: 84px;
  display: flex;
  justify-content: center;

  .content {
    padding: 60px 30px;
    max-width: 913px;

    h5 {
      text-align: center;

      &.sub-title {
        margin-bottom: 24px;
      }
    }

    h5,
    h6 {
      font-weight: 600;
    }

    p {
      text-align: left;
    }

    ol,
    ul {
      margin-left: 44px;
    }

    ul {
      margin-bottom: 1rem;
    }
  }
}

%new-design {
  p {
    margin: 0 0 32px;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #212121;
  }

  h1 {
    margin: 0 0 16px;
    font-size: 50px;
    line-height: 63px;
    font-weight: 900;
  }

  h2 {
    font-size: 40px;
    line-height: 49px;
    font-weight: 900;
  }

  h3 {
    font-size: 30px;
    line-height: 37px;
    font-weight: 900;
  }

  .intro {
    display: flex;

    .intro-statements {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 60px 0 90px;

      p,
      h1 {
        max-width: 730px;
      }

      .buttons {
        display: flex;

        .watch-video-btn {
          margin-right: 17px;
          width: 205px;
          background-color: #3ED0F9;
          display: flex;
          justify-content: center;
          align-items: center;

          .play-icon {
            margin-right: 8px;
          }
        }

        .learn-more-btn {
          width: 205px;
        }
      }
    }

    .intro-image {
      background-image: url('/images/omg.jpg');
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      width: 50%;
      min-height: 714px;
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      flex-direction: column;
      margin-bottom: 20px;

      p {
        margin: 0 0 18px;
        text-align: center;
        font-size: 13px;
        line-height: 16px;
      }

      .intro-statements {
        align-items: center;
        order: 1;
        padding: 0 18px;
        width: unset;

        .buttons {
          .watch-video-btn {
            width: 124px;
          }

          .learn-more-btn {
            width: 124px;
          }
        }
      }

      .intro-image {
        background-image: url('/images/omg_mobile.png');
        background-position: center 40%;
        width: unset;
        height: 258px;
        min-height: unset;
      }
    }
  }

  .privacy {
    display: flex;
    justify-content: center;
    padding: 100px 60px 60px;

    .privacy-image-container {
      display: flex;
      padding-right: 79px;
      width: 50%;

      .privacy-image {
        background-image: url('/images/privacy.png');
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        width: 100%;
        height: 100%;
      }
    }

    .privacy-description {
      display: flex;
      flex-direction: column;
      max-width: 840px;
      width: 50%;
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      flex-direction: column;
      padding: 0 30px;

      .privacy-image-container {
        overflow: hidden;
        margin: 0 -30px;
        padding: unset;
        width: unset;
        height: 67px;

        .privacy-image {
          background-image:
            linear-gradient(to right, rgba(62, 224, 249, 0.46), rgba(255, 13, 141, 0.46)),
            url('/images/privacy.png');
          margin: -150px 0 0 0;
          width: 100%;
          height: 386px;
        }
      }

      .privacy-description {
        align-self: center;
        align-items: center;
        margin: 16px 0 0;
        width: unset;

        h2 {
          text-align: center;
          font-size: 23px;
          line-height: 28px;
        }

        p {
          margin: 0 0 20px;
          text-align: center;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }

  .impact {
    display: flex;
    align-items: center;
    padding: 45px 60px 60px;
    background-color: #FAFAFA;

    .impact-intro {
      margin-right: 30px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        text-align: center;
        max-width: 384px;
      }

      p {
        text-align: center;
        max-width: 438px;
      }
    }

    .impact-video {
      width: 50%;
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      flex-direction: column;
      padding: 10px 20px 25px;
      background-color: #142F46;

      .impact-intro {
        margin-right: unset;
        width: unset;

        h1 {
          margin: 0;
          font-size: 18px;
          line-height: 22px;
          color: white;
        }

        p {
          margin: 8px 0;
          font-size: 12px;
          line-height: 17px;
          font-weight: 400;
          color: white;
        }
      }

      .impact-video {
        width: 100%;
      }
    }
  }

  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 70px 0;
    background-image: linear-gradient(to bottom, rgba(255, 0, 141, 0.64), rgba(62, 208, 249, 0.64));

    .feature-header {
      color: white;
      margin-bottom: 40px;
      text-align: center;
    }

    .features-container {
      background-color: white;
      box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.16);
      padding: 94px 41px 0 46px;
    }

    .learn-how-btn {
      margin: 49px 0 25px;
      width: 253px;
    }

    .disclaimer {
      text-align: center;
      color: white;
      font-size: 12px;
      line-height: 24px;
      max-width: 728px;
    }

    @media only screen and (max-width: $image-overstretched-breakpoint) {
      padding: 13px 30px;
      background-image: linear-gradient(to bottom, rgba(255, 0, 141, 0.64), rgba(62, 208, 249, 0.64), rgba(62, 208, 249, 0.64), rgba(255, 0, 141, 0.64));

      .feature-header {
        margin-bottom: 11px;
        max-width: 191px;
        font-size: 20px;
        line-height: 24px;
      }

      .features-container {
        padding: 21px 30px 0;
      }

      .learn-how-btn {
        background-color: #3ED0F9;
        margin: 18px 0 13px;
        width: 153px;
      }

      .disclaimer {
        margin-bottom: 0;
        font-size: 5px;
        line-height: 8px;
        max-width: 242px;
      }
    }
  }

  @media only screen and (max-width: $image-overstretched-breakpoint) {
    h1 {
      text-align: center;
      margin-top: 16px;
      font-size: 23px;
      line-height: 28px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
    }

    button {
      font-size: 11px;
      height: 30px;
      border-radius: 10px;
    }

    .new-design-create-agreement-footer {
      height: 550px;

      h2 {
        width: 484px;
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
}
